<template>
  <b-container fluid>
    <b-row class="text-white bg-kpmg-blue py-4 mb-4">
      <b-col>
        <h1 class="kstyle">Visitor analytics</h1>
      </b-col>
    </b-row>
    <b-row class="my-5">
      <b-col>
        <b-form inline class="mb-4">
          <label class="mr-2" for="dateInput">from</label>
          <b-form-input id="dateInput" class="mr-2" type="date" v-model="startDate" required> </b-form-input>
          <label class="mr-2" for="dateInput">to</label>
          <b-form-input id="dateInput" class="mr-2" type="date" v-model="endDate" required> </b-form-input>
          <b-form-select class="mr-2" v-model="interval" :options="intervalOptions" />
          <b-button variant="primary" v-on:click="reload">Apply</b-button>
        </b-form>
      </b-col>
    </b-row>
    <b-row class="my-5">
      <b-col>
        <h3>Unique visitors to ESG / Impact</h3>
        <div id="chart" style="width: 80%; height: 500px;"></div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3>Excel report downloads</h3>
        <div id="chartExcel" style="width: 80%; height: 500px;"></div>
      </b-col>
      <b-col>
        <h3>Word report downloads</h3>
        <div id="chartWord" style="width: 80%; height: 500px;"></div>
      </b-col>
    </b-row>
    <b-row class="my-5">
      <b-col>
        <h3>All report downloads</h3>
        <div id="chartExcelWord" style="width: 80%; height: 500px;"></div>
      </b-col>
    </b-row>
    <b-row class="my-5">
      <b-col>
        <h3>Initiatives</h3>
      </b-col>
    </b-row>
    <b-row v-if="topinitiatives">
      <b-col>
        <bar-stacked-simple v-if="Array.isArray(topinitiatives.data) && topinitiatives.data.length > 0" :config="topinitiatives" />
      </b-col>
      <b-col>
        <div><strong>List</strong></div>
        <div v-for="item in topinitiativeslist" :key="'initiative-id-'+item.id"><b-link :to="{ name: 'ImpactInitiative', params: { id: item.id } }">{{item.name}}</b-link></div>
      </b-col>
    </b-row>
    <b-row class="my-5">
      <b-col>
        <h3>Users</h3>
      </b-col>
    </b-row>
    <b-row v-if="topusers">
      <b-col>
        <bar-stacked-simple v-if="Array.isArray(topusers.data) && topusers.data.length > 0" :config="topusers" />
      </b-col>
      <b-col>
        <div><strong>Emails for correspondence</strong></div>
        <div>
          <span v-for="item in topusers.data" :key="'email'+item.name">{{item.name}}; </span>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import BarStackedSimple from '@/components/charts/v1/barStackedSimple.vue'
import moment from 'moment'
import * as echarts from 'echarts'
const theme = { color: ['#00338D', '#005EB8', '#0091DA', '#483698', '#470A68', '#6D2077', '#00A3A1', '#009A44', '#43B02A', '#EAAA00'] }
echarts.registerTheme('kpmg', theme)

export default {
  name: 'ImpactReportingMI',
  components: {
    BarStackedSimple
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.permission = ac.can(this.user.acgroups).readAny('impactreportingmi').granted
    if (this.permission) {
      this.load()
    }
  },
  data () {
    return {
      endDate: moment().format('YYYY-MM-DD'),
      startDate: '2022-01-01',
      intervalOptions: ['day', 'week', 'month', 'quarter', 'year'],
      interval: 'month',
      permission: false,
      topinitiatives: null,
      topinitiativeslist: null,
      topusers: null
    }
  },
  methods: {
    load: async function () {
      this.$logger.debug('loading timeseries-detailed started')
      try {
        const apiName = 'cosmos'
        let path = `/stats/impactreport/visitors/${this.startDate}/${this.endDate}/${this.interval}`
        let graphData = await this.$Amplify.API.get(apiName, path)
        let optionTrend = {
          toolbox: {
            show: true,
            feature: {
              dataView: { readOnly: false },
              saveAsImage: {}
            }
          },
          dataset: {
            source: graphData
          },
          xAxis: {
            type: 'time'
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: 'timeseries',
              type: 'line',
              smooth: true,
              encode: {
                y: 'count',
                x: 'date'
              }
            }
          ]
        }
        echarts.init(document.getElementById('chart'), 'kpmg').setOption(optionTrend)
        path = `/stats/impactreport/excel/${this.startDate}/${this.endDate}/${this.interval}`
        graphData = await this.$Amplify.API.get(apiName, path)
        optionTrend = {
          toolbox: {
            show: true,
            feature: {
              dataView: { readOnly: false },
              saveAsImage: {}
            }
          },
          dataset: {
            source: graphData
          },
          xAxis: {
            type: 'time'
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: 'timeseries',
              type: 'line',
              smooth: true,
              encode: {
                y: 'count',
                x: 'date'
              }
            }
          ]
        }
        echarts.init(document.getElementById('chartExcel'), 'kpmg').setOption(optionTrend)
        path = `/stats/impactreport/word/${this.startDate}/${this.endDate}/${this.interval}`
        graphData = await this.$Amplify.API.get(apiName, path)
        optionTrend = {
          toolbox: {
            show: true,
            feature: {
              dataView: { readOnly: false },
              saveAsImage: {}
            }
          },
          dataset: {
            source: graphData
          },
          xAxis: {
            type: 'time'
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: 'timeseries',
              type: 'line',
              smooth: true,
              encode: {
                y: 'count',
                x: 'date'
              }
            }
          ]
        }
        echarts.init(document.getElementById('chartWord'), 'kpmg').setOption(optionTrend)
        path = `/stats/impactreport/excel-word/${this.startDate}/${this.endDate}/${this.interval}`
        graphData = await this.$Amplify.API.get(apiName, path)
        console.log(graphData)
        optionTrend = {
          toolbox: {
            show: true,
            feature: {
              dataView: { readOnly: false },
              saveAsImage: {}
            }
          },
          dataset: {
            source: graphData
          },
          xAxis: {
            type: 'time'
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: 'timeseries',
              type: 'line',
              smooth: true,
              encode: {
                y: 'count',
                x: 'date'
              }
            }
          ]
        }
        echarts.init(document.getElementById('chartExcelWord'), 'kpmg').setOption(optionTrend)

        path = `/stats/impactreport/topinitiatives/${this.startDate}/${this.endDate}`
        const d = await this.$Amplify.API.get(apiName, path)
        this.topinitiativeslist = d.map(x => x)
        this.topinitiativeslist.reverse()
        this.topinitiatives = {
          data: d,
          name: 'Top initiatives',
          header: 'Top initiatives',
          headerRecord: 'initiatives',
          height: '500px',
          width: '100%',
          key: 'topinitiatives'
        }
        path = `/stats/impactreport/topusers/${this.startDate}/${this.endDate}`
        const users = await this.$Amplify.API.get(apiName, path)
        this.topusers = {
          data: users,
          name: 'Top users',
          header: 'Top users',
          headerRecord: 'users',
          height: '500px',
          width: '100%',
          key: 'topinitiatives'
        }
      } catch (e) {
        this.$logger.warn('loading error' + e)
      }
    },
    reload: function () {
      echarts.dispose(document.getElementById('chart'))
      echarts.dispose(document.getElementById('chartExcel'))
      echarts.dispose(document.getElementById('chartWord'))
      echarts.dispose(document.getElementById('chartExcelWord'))
      this.load()
    }
  }
}
</script>

<style></style>
